import React from "react";
import styled from "styled-components";
import { Button } from "../button";
import { Logo } from "../logo";
import { Marginer } from "../marginer";

const NavbarContainer = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BrandContainer = styled.div``;

const AccessibilityContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LoginButton = styled(Button)`
  background-color: transparent;
  border: none;

  &:hover {
    background-color: transparent;
    border: none;
    color: rgb(212, 212, 212);
  }
`;

export function Navbar(props) {
  return (
    <NavbarContainer>
      <BrandContainer>
        <Logo inline />
      </BrandContainer>
      <AccessibilityContainer>
        <Button 
          onClick={() => window.open('//www.cryptosportstars.com')}
          rel="noopener noreferrer" target="_blank" small
        >
          Crypto Sport Stars
        </Button>
        <div style={{ width: '20px' }}></div>
        <Button 
          // onClick={() => window.open('//www.cryptosportstars.com')}
          rel="noopener noreferrer" target="_blank" small
        >
          Crypto Ballers coming soon
        </Button>
      </AccessibilityContainer>
    </NavbarContainer>
  );
  
}
